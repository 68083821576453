// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();

import '../src/javascript/channels/index.js';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import $ from "jquery";
import "popper.js";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/modal";
import "bootstrap/scss/bootstrap";
import toastr from "toastr";
import { toggleYesNo } from '../src/js/toggle_yes_no';
import { displayMatrimonialRegimeFields } from '../src/js/matrimonial_regime';
import { addJob, deleteJob } from '../src/js/job';
import { displayBeneficialOwnerSection, addBeneficialOwner, deleteBeneficialOwner } from '../src/js/beneficial_owners'
import { toggleBusinessFormationFields } from '../src/js/toggle_business_formation_fields';
import { toggleSupportFields } from '../src/js/toggle_support_fields';
import { toggleInnovationDetailsField } from '../src/js/toggle_innovation_details_field';
import { addOptionalDocumentCompany, addOptionalDocumentNonprofitAndIndividual, deleteOptionalDocument } from '../src/js/optional_documents';
import { initializeHomepageCounters } from '../src/js/home_counter';

// CSS
import '../src/scss/site'

require('redactor/redactor.min');
require('redactor/_langs/fr');
require('redactor/_langs/en');
require('redactor/_plugins/table/table.min');
require ('../src/js/navbar');
require ('../src/js/input-file');
require ('../src/js/scrollreveal-init');
require ('../src/js/password_strenght_verificator');
require ('../src/js/dependent_children');

toastr.options.toastClass = 'toastr-medium-mtop';

toastr.options.timeOut = '0'
toastr.options.extendedTimeOut = '0'

window.toastr = toastr;

$(document).ready(() => {
  $('button#add-new-job').on('click', addJob);
  $('form').on('click', '.destroy-job', deleteJob);
  $("input.toggle[type=checkbox]").change(toggleYesNo);
  $('#funding_application_entity_attributes_legal_form').change(displayBeneficialOwnerSection)
  $('button#add-new-beneficial-owner').on('click', addBeneficialOwner);
  $('form').on('click', '.destroy-beneficial-owner', deleteBeneficialOwner);
  $('#funding_application_user_business_formation').change(toggleBusinessFormationFields);
  $('#funding_application_user_marital_status').change(displayMatrimonialRegimeFields);
  $('#funding_application_support').change(toggleSupportFields);
  $('#funding_application_economic_model_attributes_innovation').change(toggleInnovationDetailsField);
  $('button#add-new-optional-document-nonprofit-and-individual').on('click', addOptionalDocumentNonprofitAndIndividual);
  $('button#add-new-optional-document-company').on('click', addOptionalDocumentCompany);
  $('form').on('click', '.destroy-optional-document', deleteOptionalDocument);

  init_redactor();
  $('[data-bs-toggle="tooltip"]').tooltip();
  $('[data-bs-toggle="modal"]').modal();

  let toast = $('.toast-warning')[0];
  let btn = $('#connection-btn')[0];
  if (toast !== undefined && toast.textContent.includes('seconde(s)')){
    toastrTimer(toast);
    btn.disabled = true;
  }

  initializeHomepageCounters();

  $(window).scroll(function() {
    initializeHomepageCounters();
  });
});

function init_redactor() {
  $R(".redactor", {
    buttons: [
      "format",
      "redo",
      "undo",
      "fontcolor",
      "bold",
      "italic",
      "underline",
      "lists",
      "table",
      "image",
      "link",
      "alignment",
      "html"
    ],
    plugins: ['table'],
    lang: "fr",
    formatting: ["p", "h1", "h2", "blockquote"]
  });
}

function toastrTimer(toastr) {
  let i = 0;
  let x = setInterval(function() {
    let time = 59;
    let seconds = parseInt(time)-i;
    toastr.textContent = `Trop d'essais ! Avant de vous reconnecter vous devez attendre: ${seconds} seconde(s)`;
    i+=1;
    if (seconds === 0) {
      clearInterval(x);
      $('#connection-btn')[0].disabled = false
      toastr.remove();
    }
  }, 1000);
}
